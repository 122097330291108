<template>
     <div class="row justify-content-center p-3">
      <loader v-if="loading" :msg="loadingMsg"/>
      
      <div class="container-fluid m-0 p-0 min-height" v-else-if="!orderSelected">
          <div class="row justify-content-center mt-2">
                <div class="col-auto">
                    <label class="form-label">{{$t('order.order')}}:</label>
                    <div class="input-group">
                        <span style="margin-right:.25rem!important;color:#333;background:transparent">XX-</span>
                        <input v-model="search" class="form-control iniput">
                    </div>
                </div>
                <div class="col-auto">
                    <label class="form-label">{{$t('order.creationDate')}}:</label>
                    <date-picker class="iniput" v-model="filters.creationDate" range/>
                </div>
                <div class="col-auto">
                    <label class="form-label">{{$t('order.deliveryPromiseDateToCarrier')}}:</label>
                    <date-picker class="iniput" v-model="filters.deliveryPromiseDateToCarrier" range/>
                </div>
                <div class="col-auto">
                    <label class="form-label">{{$t('order.status')}}:</label>
                    <select v-model="filters.status" class="form-control iniput">
                        <option :value="null">{{$t('all')}} </option>
                        <option v-for="(s,i) in statuses" :key="`st${i}`" :value="s">{{$t(`status.${s}`)}}</option>
                    </select>
                </div>
                <div class="col-auto">
                    <label class="form-label">{{$t('order.carrierName')}}:</label>
                    <model-list-select :list="getCarriers()" option-value="id" class="form-control" style="min-width:150px" 
                        :custom-text="codeAndNameCarriers" v-model="filters.carrier" :placeholder="$t('all')"/>
                </div>
                 <div v-if="isAdmin() || isProvider()" class="col-auto align-self-end" style="display:flex; flex-direction:row">
                    <div class="form-check form-switch" style="padding-top:1rem">
                        <input class="form-check-input" type="checkbox" id="notifyMany" v-model="notifyMany">
                        <label class="form-check-label" for="notifyMany">{{$t('preparation.notify_many')}}</label>
                    </div>
                    <div v-if="notifyMany" class="form-check mt-3 ms-2">
                        <label class="form-check-label" for="checkAllOrders">{{$t('all')}}</label>
                        <input class="form-check-input" type="checkbox" id="checkAllOrders" v-model="checkAllOrders" @change="checkAll()">
                    </div>
                    <span v-if="notifyMany" class="far fa-bell mt-3 ms-2" :class="{'disabled': none2Notify()}" :title="$t('preparation.notify')" @click="!none2Notify()? notifyAll():''"></span>
                </div>
          </div>
          <div class="row justify-content-center mt-4">
            <div v-for="(h,i) in headers" :class="`col-2 cell table-header ${h.sortable?'sortable':''}`" :key="`header${i}`" @click="sortTable(h.val)">
                {{getText(h.text)}} <i v-if="sort.key==h.val" :class="`fas fa-sort-amount-${sort.order=='ASC'?'up':'down'}`"></i>
            </div>
            <div class="col-2 cell table-header" style="text-align:end;padding-right:20px!important">{{$t('options')}}</div>
          </div>
            <order-list :orders="getFilteredOrders()" :notifyMany="notifyMany" @check="check"
                @orderSelect="orderSelect" @print="printTickets" @notify="notify"/>
      </div>
      
      <div class="col-12" v-else>
        <order-prepare :order="orderSelected" @exit="orderSelected=null;loadOrders()"/>
      </div>
    </div>
</template>
<script>
import Loader from '@/components/Loader'
import DatePicker from 'vue2-datepicker';
import * as orderService from '@/services/orders'
import {getProviderList} from '@/services/providers'
import OrderList from '@/components/OrderList.vue'
import OrderPrepare from '@/components/OrderPrepare.vue'
import {printOnWindow} from '@/services/utils'
import {STATUSES} from '@/services/utils'
import {format, subDays} from 'date-fns'
import {ModelListSelect} from 'vue-search-select'

export default {
    components: { DatePicker, OrderList, OrderPrepare, Loader, ModelListSelect },
    data: ()=> {
        return {
            loading: true,
            loadingMsg:'',
            search: '',
            providers:[],
            carriers:[],
            orders: [],
            orderSelected:null,
            notifyMany: false,
            checkAllOrders: false,
            filters:{
                creationDate: [ subDays(new Date(),7), new Date()],
                deliveryPromiseDateToCarrier: null,
                status: null,
                carrierName: null,
                provider: 'all',
                carrier: 'all'
            },
            statuses: STATUSES,
            sort:{ key: null, order: 'DESC'},
            headers:[
                { val:'orderNumber', text: 'order', sortable: true },
                { val:'status', text: 'status', sortable: true},
                { val:'creationDate', text: 'creationDate', sortable: true},
                { val:'deliveryPromiseDateToCarrier', text: 'deliveryPromiseDateToCarrier', sortable: true},
                { val:'carrierName', text: 'carrierName', sortable: true}
            ],
        }
    },
    watch:{
        filters: {
            handler: function(newVal){ 
                this.loadCarriers() 
                this.loadOrders()
            },
            deep: true
        },
        providerImpersonate: function(newVal){ this.filters.provider=newVal??'all' },
        search: function(newVal){ if(!newVal || newVal.length>=3) this.loadOrders()}
    },
    mounted(){ 
        this.loadProviders()
        this.loadCarriers()
        this.loadOrders()
        this.filters.provider=this.providerImpersonate
    },
    methods:{
        async loadProviders(){
            try{
                let {data} = await getProviderList()
                this.providers = [{leroy_id:'all', name: this.$t('all')}].concat(data)
            }catch(err){ console.log(err)}
        },
        async loadCarriers(){
            try{
                let {data} = await orderService.getCarriers(this.providerImpersonate)
                this.carriers = data
            }catch(err){ console.log(err)}
        },
        getCarriers(){
            return [...[{id:'all', name: this.$t('all')}, ...this.carriers]]
        },
        async loadOrders(){
            this.loadingMsg ='getting_orders'
            this.loading=true
            this.filters.provider=this.providerImpersonate
            let query = JSON.parse(JSON.stringify(this.filters))
            Object.keys(query).forEach(k=> { 
                if(!query[k] || ((k=='provider' || k=='carrier') && query[k]=='all')) delete query[k] 
                else if(k.includes('deliveryPromiseDateToCarrier')){
                    if(query[k][0]) query.dateStartDeliveryPromiseDateToCarrier = format(new Date(query[k][0]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                    if(query[k][1]) query.dateEndDeliveryPromiseDateToCarrier = format(new Date(query[k][1]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                    delete query[k]
                } else if(k.includes('Date')){
                    if(query[k][0]) query.dateStart = format(new Date(query[k][0]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                    if(query[k][1]) query.dateEnd = format(new Date(query[k][1]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                    delete query[k]
                }
            })
            if(this.search && this.search.length>=3) query.orderNumber = this.search
            if(this.sort.key) query.sort = `${this.sort.key}.${this.sort.order}`
            try{
                let {data} = await orderService.getActiveOrders(query)
                this.orders = data
            }catch(err){
                console.log(err)
                this.$swal('ERROR', this.$t('error_getting_orders'), 'error')
            }         
            this.loading=false   
        },
        getText(text){ return (['order','bulks'].includes(text)? 'Nº ':'') +this.$t(`order.${text}`)},
        getFilteredOrders(){
            return this.orders.filter(o=> !this.search || `${o.storeCode}-${o.orderNumber}`.includes(this.search))
        },
        check(event){
            let orderFound = this.orders.find(o=> o._id==event.id)
            if (orderFound) orderFound.checked = event.checked? false: true
            this.$forceUpdate()
        },
        checkAll(){
            let ordersFound = this.orders.filter(o=> o.transport && o.status=='labeled')
            ordersFound.map(o=> o.checked = checkAllOrders.checked)
            this.$forceUpdate()
        },
        sortTable(key){
            this.sort.order = this.sort.key == key && this.sort.order == 'ASC'? 'DESC':'ASC'
            this.sort.key = key
            this.loadOrders()
        },
        orderSelect(order){ this.orderSelected = order},
        async printTickets(order){
            this.loadingMsg ='preparation.get_ticket'
            this.loading = true
            try{
                let { data } = await orderService.reprint(order)
                printOnWindow(data.pdf, order)
            }catch(err){
                this.$swal('Error', this.$t('preparation.error_get_ticket'), 'error')
            }
            this.loading=false
            
        },
        none2Notify(){
             return this.orders.filter(o=> o.checked).length==0},
        notifyAll(){
            const ordersChecked = JSON.parse(JSON.stringify(this.orders)).filter(o=>o.checked)
            this.notify(ordersChecked)
        },
        async notify(order){
            this.loadingMsg ='preparation.sending_notif'
            this.loading = true
            try{

                try{
                    let jsonData = Array.isArray(order)? JSON.parse(JSON.stringify(order)) : JSON.parse(JSON.stringify(Array(order)));
                    const labels = {
                        title: this.$t('manifest.title'),
                        provider: this.$t('manifest.provider'),
                        date: this.$t('manifest.date'),
                        orderReference: this.$t('manifest.orderReference'),
                        orderNumber: this.$t('manifest.orderNumber'),
                        storeNumber: this.$t('manifest.storeNumber'),
                        supplierNumber: this.$t('manifest.supplierNumber'),
                        deliveryPostcode: this.$t('manifest.deliveryPostcode'),
                        deliveryTown: this.$t('manifest.deliveryTown'),
                        customerSurname: this.$t('manifest.customerSurname'),
                        supplierReference: this.$t('manifest.supplierReference'),
                        packages: this.$t('manifest.packages'),
                        weight: this.$t('manifest.weight'),
                        carrierStamp: this.$t('manifest.carrierStamp'),
                        totalOrders: this.$t('manifest.totalOrders'),
                        carrier: this.$t('manifest.carrier')
                    }
                    const groupData = jsonData.reduce((hash, obj) => {
                        if(obj['transport']['carrier']['id'] === undefined) return hash; 
                        return Object.assign(hash, { [obj['transport']['carrier']['id']]:( hash[obj['transport']['carrier']['id']] || [] ).concat(obj)})
                    }, {})
                    const keys = Object.keys(groupData)
                    for (let i=0; i<keys.length; i++)
                    {
                        const manifestData = groupData[keys[i]]
                        let {data} = await orderService.getManifest({ data : manifestData, labels: labels})
                        printOnWindow(data, manifestData[0].orderNumber??'')
                    };

                    let {data} = await orderService.notifyOrders(Array.isArray(order)?order.map(o=> o._id) :[order._id])
                    if(data.length==0) this.$swal(this.$t('success'), this.$t('preparation.success_sending_notif'), 'success')
                    else this.$swal({
                        title: this.$t('warn'),
                        html: `<h5>${this.$t('preparation.orders_non_notified')}:</h5
                            <div style="display:flex;flex-direction:column"> 
                                ${data.map(d=> `<label style="border-bottom:1px solid #CCC">${d.id_store}-${d.id_order}</label>`)}
                                </div>`,
                        type: 'warn'
                            
                    })
                }catch(err){
                    console.log(err)
                    this.$swal('Error', this.$t('preparation.error_sending_notif'), 'error')
                }
                this.loadOrders()

            }catch(err){
                console.log(err)
                this.$swal('ERROR', this.$t('preparation.error_sending_notif'), 'error')
            }
            this.loading = false
        },
    }
}
</script>
<style lang="scss" scoped>
.form-control{
    height: 34px;
    line-height: 20px;
    color:#888;
}
.form-label{
    color:$green;
    margin-bottom:0;
}

span{
    margin-left: .4rem;
    color:white;
    border-radius: 4px;
    width: 1.6rem;
    height: 1.6rem;
    font-size: 1rem;
    padding-top: .3rem;
    text-align: center;
    background: sandybrown;
    cursor: pointer;
    
    &:hover{
        filter: brightness(90%);
    }
    &.disabled{
        filter: grayscale(50%);
        opacity: .3;
        cursor: not-allowed;
    }
    
}


.cell{
    padding: 8px!important;
    font-size: .9rem;
    line-height: .9rem;
    background: white;
    color: $dark-gray;
    box-shadow: 1px 2px 2px 0 $shadow;
    font-family: 'LM-regular', 'Helvetica', 'Arial';
}
.table-header{
    background: rgb(197, 253, 197);
    font-family: 'LM-Semibold';
    font-size: 1rem;
    line-height: 1rem;
    &.sortable:hover{
        filter:brightness(95%);
        cursor: pointer;
    }
}
.min-height{
    min-height: 300px;
}
</style>